// extracted by mini-css-extract-plugin
export var container = "OurVision-module--container--A4y0x";
export var contentWrapper = "OurVision-module--contentWrapper--Wy7H1";
export var ctas = "OurVision-module--ctas--6luoL";
export var customerStoriesCTA = "OurVision-module--customerStoriesCTA--As0Vt";
export var joinTeamCTA = "OurVision-module--joinTeamCTA--GYyOc";
export var limitWidthOnLargeScreens = "OurVision-module--limitWidthOnLargeScreens--fQS1W";
export var sectionWrapper = "OurVision-module--sectionWrapper--61SYZ";
export var text = "OurVision-module--text--bKw4K";
export var title = "OurVision-module--title--wLJwi";
export var titleWrapper = "OurVision-module--titleWrapper--6llyD";