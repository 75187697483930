import React, { FC } from "react";
import * as styles from "./OurVision.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import { Link } from "@components";
interface IProps {}

const OurVision: FC<IProps> = () => {
	return (
		<section className={styles.sectionWrapper}>
			<div className={styles.container}>
				<div className={styles.titleWrapper}>
					<TypographyV2
						className={styles.title}
						variant="HEADING_2"
						color="brand-500"
					>
						Our vision
					</TypographyV2>
					<div className={styles.ctas}>
						<Link to="#join_the_team">
							<TypographyV2
								variant="BODY_TEXT_LARGE"
								color="brand-600"
								weight="SEMIBOLD"
								className={styles.joinTeamCTA}
							>
								Join our team
							</TypographyV2>
						</Link>
						<Link to="/case-studies">
							<TypographyV2
								variant="BODY_TEXT_LARGE"
								color="brand-600"
								weight="SEMIBOLD"
								className={styles.customerStoriesCTA}
							>
								Customer stories
							</TypographyV2>
						</Link>
					</div>
				</div>
				<div className={styles.contentWrapper}>
					<TypographyV2
						weight="MEDIUM"
						variant="HEADING_5"
						color="text-secondary"
					>
						Biotech has always led the way in breakthroughs.
					</TypographyV2>
					<TypographyV2
						variant="BODY_TEXT_EXTRA_LARGE"
						mobileVariant="BODY_TEXT_EXTRA_LARGE"
						weight="REGULAR"
						color="text-helper"
					>
						But producing therapeutics is complex and requires an
						enormous amount of organization and tracking. In the
						last few years, every team has undergone a digital
						transformation, and every team is accumulating more and
						more data. Research teams that best use their data will
						create the next breakthrough.
					</TypographyV2>
					<TypographyV2
						weight="MEDIUM"
						variant="HEADING_5"
						color="text-secondary"
					>
						But, with more data, it becomes harder to manage.{" "}
					</TypographyV2>
					<TypographyV2
						variant="BODY_TEXT_EXTRA_LARGE"
						mobileVariant="BODY_TEXT_EXTRA_LARGE"
						weight="REGULAR"
						color="text-helper"
					>
						Many experiments are unnecessary duplicates due to a
						lack of interoperability into past experiments. This
						limits the exchange and use of data from different
						sources.
					</TypographyV2>
					<TypographyV2
						variant="BODY_TEXT_EXTRA_LARGE"
						mobileVariant="BODY_TEXT_EXTRA_LARGE"
						weight="REGULAR"
						color="text-helper"
					>
						Low reproducibility rates within life science research
						undermine cumulative knowledge production and contribute
						to both delays and costs of therapeutic drug
						development.
					</TypographyV2>
					<TypographyV2
						variant="BODY_TEXT_EXTRA_LARGE"
						mobileVariant="BODY_TEXT_EXTRA_LARGE"
						weight="REGULAR"
						color="text-helper"
					>
						Unusable results are the consequence. For example, the
						problem and cost of misidentified and contaminated cell
						lines – and their impact on reproducibility – cannot be
						overestimated.
					</TypographyV2>
					<TypographyV2
						weight="MEDIUM"
						variant="HEADING_5"
						color="text-secondary"
					>
						At Genemod, we want to change this.
					</TypographyV2>
					<TypographyV2
						variant="BODY_TEXT_EXTRA_LARGE"
						mobileVariant="BODY_TEXT_EXTRA_LARGE"
						weight="REGULAR"
						color="text-helper"
					>
						We bring your team’s work together in one shared space.
						Improve process management by leaving the repetitive
						stuff to us so you can do more of the work you do best.
					</TypographyV2>
					<TypographyV2
						variant="BODY_TEXT_EXTRA_LARGE"
						mobileVariant="BODY_TEXT_EXTRA_LARGE"
						weight="REGULAR"
						color="text-helper"
					>
						We believe that the lab of the future won’t run on the
						same technology scientists have been using for decades.
						Researchers now expect flexible, intuitive solutions
						that enable collaboration and creativity.
					</TypographyV2>
					<TypographyV2
						variant="HEADING_5"
						weight="BOLD"
						color="brand-300"
						style={{ marginBottom: 0 }}
					>
						That’s why we built Genemod.
					</TypographyV2>
				</div>
			</div>
		</section>
	);
};

export default OurVision;
