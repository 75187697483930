import React, { FC } from "react";
import * as styles from "./AboutUsStats.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import { companyHighlights } from "./data";
interface IProps {}

const AboutUsStats: FC<IProps> = () => {
	return (
		<section className={styles.sectionWrapper}>
			<div className={styles.container}>
				{companyHighlights.map((stat, idx) => {
					return (
						<div className={styles.highlights} key={idx}>
							<TypographyV2
								className={styles.metric}
								weight="MEDIUM"
								variant="HEADING_1"
								color="violet-500"
							>
								{stat?.metric}
							</TypographyV2>
							<TypographyV2
								className={styles.context}
								variant="BODY_TEXT_EXTRA_LARGE"
								color="text-helper"
							>
								{stat?.context}
							</TypographyV2>
							<TypographyV2
								className={styles.description}
								variant="BODY_TEXT_MEDIUM"
								color="text-helper"
							>
								{stat?.detail}
							</TypographyV2>
						</div>
					);
				})}
			</div>
		</section>
	);
};

export default AboutUsStats;
