import React, { useRef } from "react";
import { PageLayout } from "@components/index";
import Hero from "@components/AboutUs/Hero";
import Margins from "@components/Margins/Margins";
import * as styles from "./about-us-v4.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import AboutUsStats from "@components/AboutUs/AboutUsStats";
import PartnerLogos from "@components/AboutUs/PartnerLogos";
import OurMission from "@components/AboutUs/OurMission";
import OurVision from "@components/AboutUs/OurVision";
import JoinTheTeam from "@components/AboutUs/JoinTheTeam";
import GENEMOD_FREEZER from "@assets/products/genemod-intro-video.mp4";

export default function AboutUsPage() {
	const videoRef = useRef<HTMLVideoElement>(null);

	return (
		<PageLayout
			footerTheme={"dark"}
			seoOptions={{ type: "PREDEFINED", pageName: "about-us" }}
			defaultHeader="light"
		>
			<div>
				<div className={styles.sectionsWrapper}>
					<div className={styles.sectionContainer}>
						<Margins>
							<Hero />
							{/* <StaticImage
								loading="eager"
								src="../../assets/images/about/about-genomod.png"
								alt="About genemod"
							/> */}
							<div className={styles.videoWrapper}>
								<video
									ref={videoRef}
									className={styles.videoContainer}
									muted
									preload="auto"
									autoPlay={true}
									controls={true}
								>
									<source
										src={GENEMOD_FREEZER}
										type="video/mp4"
									/>
								</video>
							</div>
							<AboutUsStats />
							<div className={styles.sectionDivider}></div>
							<PartnerLogos />
						</Margins>
					</div>
				</div>
				<div className={styles.brandSectionsWrapper}>
					<Margins className={styles.brandSectionsContainer}>
						<OurMission />
						<OurVision />
					</Margins>
				</div>
				<div className={styles.joinTheTeamSectionWrapper}>
					<Margins className={styles.joinTheTeamContainer}>
						<JoinTheTeam />
					</Margins>
				</div>
			</div>
		</PageLayout>
	);
}
