type Highlights = {
	metric: string;
	context: string;
	detail: string;
};

export const companyHighlights: Highlights[] = [
	{
		metric: "$7M+",
		context: "in funding",
		detail: "We are backed by leading VCs",
	},
	{
		metric: "93%",
		context: "Error reduction",
		detail: "Our customers reported significant reduction in error.",
	},
	{
		metric: "80%",
		context: "Compliance boost",
		detail: "Genemod promotes universal work recognition and legality.",
	},
	{
		metric: "4.8 stars",
		context: "Rating on G2",
		detail: "We are recognized as the top performer for 6 seasons in a row.",
	},
];
