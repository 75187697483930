import React, { FC } from "react";
import * as styles from "./Hero.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
interface IProps {}

const Hero: FC<IProps> = () => {
	return (
		<section className={styles.sectionWrapper}>
			<div className={styles.heroArea}>
				<div className={styles.titleWrapper}>
					<TypographyV2
						variant="BODY_TEXT_EXTRA_LARGE"
						color="text-secondary"
						weight="SEMIBOLD"
						className={styles.tagline}
					>
						About Genemod
					</TypographyV2>
					<TypographyV2
						className={styles.heading}
						variant="HEADING_1"
						color="brand-500"
					>
						Empower the next generation of research
					</TypographyV2>
				</div>
				<TypographyV2
					className={styles.text}
					variant="BODY_TEXT_EXTRA_LARGE"
					weight="REGULAR"
					color="text-secondary"
				>
					Rooted in expertise and forward-thinking, Genemod is a
					community shaping the future of research. Join us as we push
					boundaries and embrace limitless possibilities in science.
				</TypographyV2>
			</div>
		</section>
	);
};

export default Hero;
