import React, { FC } from "react";
import * as styles from "./JoinTheTeam.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import { StaticImage } from "gatsby-plugin-image";

interface IProps {}
type JProps = {
	position: string;
	location: string;
	jobType: string;
};
const JoinTheTeam: FC<IProps> = () => {
	const jobs: JProps[] = [
		{
			position: "Business Development Rep [Closed]",
			location: "Seattle, WA or remote",
			jobType: "Full-time",
		},
		{
			position: "Software Engineer (Full Stack) [Closed]",
			location: "Seattle, WA or remote",
			jobType: "Full-time",
		},
		{
			position: "Test Engineer Intern [Closed]",
			location: "Seattle, WA or remote",
			jobType: "Full-time / Contract",
		},
	];
	return (
		<section id="join_the_team" className={styles.sectionWrapper}>
			<TypographyV2
				variant="HEADING_2"
				weight="MEDIUM"
				color="text-primary"
				style={{ marginBottom: 0, textAlign: "center" }}
			>
				Join the team
			</TypographyV2>
			<div className={styles.jobs}>
				{jobs?.map((job, idx) => {
					return <JobCard key={idx} {...job} />;
				})}
			</div>
		</section>
	);
};

export default JoinTheTeam;

const JobCard: FC<any> = (job) => {
	return (
		<div className={styles.jobCard}>
			<TypographyV2
				variant="HEADING_5"
				weight="MEDIUM"
				color="text-secondary"
				style={{ marginBottom: "16px" }}
			>
				{job.position}
			</TypographyV2>
			<div className={styles.tags}>
            <div className={styles.tag}>
				<StaticImage
					loading="eager"
					src="../../../assets/icons/location.png"
					alt="Location"
                    className={styles.locationIcon}
				/>
				<TypographyV2
					color="text-helper"
					variant="BODY_TEXT_LARGE"
					weight="REGULAR"
					style={{ marginBottom: 0 }}
				>
					{job.location}
				</TypographyV2>
			</div>
            <div className={styles.tag}>
				<StaticImage
					loading="eager"
					src="../../../assets/icons/location.png"
					alt="Location"
                    className={styles.locationIcon}
				/>
				<TypographyV2
					color="text-helper"
					variant="BODY_TEXT_LARGE"
					weight="REGULAR"
					className={styles.join_location}
					style={{ marginBottom: 0 }}
				>
					{job.jobType}
				</TypographyV2>
			</div>
            </div>
            
		</div>
	);
};
