import React, { FC } from "react";
import * as styles from "./OurMission.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
interface IProps {}

const OurMission: FC<IProps> = () => {
	return (
		<section className={styles.sectionWrapper}>
			<div className={styles.container}>
				<div className={styles.titleWrapper}>
					<TypographyV2
						className={styles.title}
						variant="HEADING_2"
						color="brand-500"

					>
						Great work is no accident
					</TypographyV2>
				</div>
				<div className={styles.descriptionWrapper}>

				<TypographyV2
					className={styles.text}
					variant="BODY_TEXT_EXTRA_LARGE"
					mobileVariant="BODY_TEXT_EXTRA_LARGE"
					weight="REGULAR"
					color="text-secondary"
				>
					Our mission is to empower teams to create the next breakthrough. By caring deeply about each other, our customers, and our mission, we’re building a company that’s proud to put people first.
				</TypographyV2>
				</div>
			</div>
		</section>
	);
};

export default OurMission;
