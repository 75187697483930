// extracted by mini-css-extract-plugin
export var brandSectionsContainer = "about-us-v4-module--brandSectionsContainer--cZmfg";
export var brandSectionsWrapper = "about-us-v4-module--brandSectionsWrapper--CrQRf";
export var joinTheTeamContainer = "about-us-v4-module--joinTheTeamContainer--0TM9w";
export var joinTheTeamSectionWrapper = "about-us-v4-module--joinTheTeamSectionWrapper--pFqpz";
export var limitWidthOnLargeScreens = "about-us-v4-module--limitWidthOnLargeScreens--kLjrW";
export var sectionContainer = "about-us-v4-module--sectionContainer--LFmzH";
export var sectionDivider = "about-us-v4-module--sectionDivider--K1UjB";
export var sectionsWrapper = "about-us-v4-module--sectionsWrapper--jWIe0";
export var videoContainer = "about-us-v4-module--videoContainer--hqh9-";
export var videoWrapper = "about-us-v4-module--videoWrapper--aNfb9";