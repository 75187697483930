import React, { FC } from "react";
import * as styles from "./PartnerLogo.module.scss";
import TypographyV2 from "@components/TypographyV2/TypographyV2";
import { investorLogos } from "./data";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
interface IProps {}

const PartnerLogos: FC<IProps> = () => {
	
	const investorLogo = [
		{
			logo: (
				<div className={styles.defy}>
					<StaticImage
						loading="eager"
						src="../../../assets/images/about/defy.png"
						alt="Defy"
						width={103}
					/>
				</div>
			),
		},
		{
			logo: (
				<div className={styles.ldv}>
					<StaticImage
						loading="eager"
						src="../../../assets/images/about/LDV-partners.png"
						alt="LDV Partners"
						width={38}
					/>
				</div>
			),
		},
		{
			logo: (
				<div className={styles.dolby}>
					<StaticImage
						loading="eager"
						src="../../../assets/images/about/Dolby-ventures.png"
						alt="Dolby ventures"
						width={166}
					/>
				</div>
			),
		},
		{
			logo: (
				<div className={styles.cercano}>
					<StaticImage
						loading="eager"
						src="../../../assets/images/about/cercano.png"
						alt="Cercano"
						width={161}
					/>
				</div>
			),
		},
		{
			logo: (
				<div className={styles.amino}>
					<StaticImage
						loading="eager"
						src="../../../assets/images/about/amino.png"
						alt="Amino"
						width={208}
					/>
				</div>
			),
		},
		{
			logo: (
				<div className={styles.canaan}>
					<StaticImage
						loading="eager"
						src="../../../assets/images/about/canaan.png"
						alt="Canaan"
						width={150}
					/>
				</div>
			),
		},
	];
	return (
		<section className={styles.sectionWrapper}>
			<div className={styles.container}>
				<TypographyV2
					variant="HEADING_4"
					weight="REGULAR"
					tabletVariant="HEADING_4"
					mobileVariant="HEADING_4"
					color="text-primary"
					className={styles.title}
				>
					Backed by world-class investors
				</TypographyV2>
				<div className={styles.logosWrapper}>
					{investorLogo.map((logo, idx) => {
						return <div key={idx}>{logo.logo}</div>;
					})}
				</div>
			</div>
		</section>
	);
};

export default PartnerLogos;
