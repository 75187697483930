// extracted by mini-css-extract-plugin
export var amino = "PartnerLogo-module--amino--Oa-Wx";
export var canaan = "PartnerLogo-module--canaan--Lm11H";
export var cercano = "PartnerLogo-module--cercano--BHsjU";
export var container = "PartnerLogo-module--container--Vjpp5";
export var defy = "PartnerLogo-module--defy--fB6Jd";
export var dolby = "PartnerLogo-module--dolby--6G2Sb";
export var ldv = "PartnerLogo-module--ldv--STGYc";
export var limitWidthOnLargeScreens = "PartnerLogo-module--limitWidthOnLargeScreens--btHG4";
export var logosWrapper = "PartnerLogo-module--logosWrapper--IFEQ0";
export var sectionWrapper = "PartnerLogo-module--sectionWrapper--aX-In";
export var title = "PartnerLogo-module--title--ZluN6";